import React from 'react';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { injectIntl, intlShape } from '../../util/reactIntl';
import { camelize } from '../../util/string';

import PageBuilder from '../../containers/PageBuilder/PageBuilder';

import FallbackPage from './FallbackPage';
import './LandingPage.css';
import { ASSET_NAME } from './LandingPage.duck';
import { propTypes } from '../../util/types';
import config from '../../config';

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;
  const { siteTitle, canonicalRootURL } = config;
  const additionalSchema = [
    {
      '@context': 'https://schema.org',
      '@type': 'Corporation',
      name: `${siteTitle}`,
      url: `${canonicalRootURL}`,
      logo: '',
      sameAs: [process.env.REACT_APP_FACEBOOK_APP_URL, process.env.REACT_APP_INSTAGRAM_APP_URL],
    },
    {
      '@context': 'https://schema.org/',
      '@type': 'WebSite',
      name: 'Swivospace',
      url: `${canonicalRootURL}`,
      potentialAction: {
        '@type': 'SearchAction',
        target: `${canonicalRootURL}/spaces?keywords={search_term_string}`,
        'query-input': 'required name=search_term_string',
      },
    },
  ];

  return (
    <div className="landing__page">
      <PageBuilder
        pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
        inProgress={inProgress}
        error={error}
        additionalSchema={additionalSchema}
        fallbackPage={<FallbackPage error={error} />}
      />
    </div>
  );
};

LandingPageComponent.propTypes = {
  // from injectIntl
  intl: intlShape.isRequired,
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(withRouter, connect(mapStateToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
